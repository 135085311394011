import Vue from 'vue'

const state = {

}
const getters = {
    meta (state, getters) {
        return state;
    }
};
const actions = {
    setMeta ({ state, commit }, meta) {
        commit('setMeta', meta);
    }
};

const mutations = {
    setMeta (state, meta) {
        if (state.meta !== meta) {
            if (meta === null) {
                for (const prop of Object.keys(state)) {
                    Vue.set(state, prop, null);
                }
            } else {
                for (const prop of Object.keys(meta)) {
                    Vue.set(state, prop, meta[prop]);
                }
            }
        }
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
