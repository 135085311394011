import _ from 'lodash'

const state = {
    Page: 1,
    PerPage: 25,
    Query: '',
    Sort: {
        RunTime: 'desc',
    },
}
const getters = {
    params () {
        return state;
    }
};
const actions = {
    updateParams ({ state, commit }, params) {
        commit('updateParams', params);
    }
};

const mutations = {
    updateParams (state, params) {
        Object.keys(params).forEach((p) => {
            _.set(state, p, params[p]);
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
