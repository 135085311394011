import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                pageDarkBg: true,
            }
        },
        {
            path: '/lookup/:lookupId',
            name: 'lookup-result',
            component: () => import('./views/RateLookupResult.vue')
        },
        {
            path: '/max-lookups-used',
            name: 'max-lookups-used',
            component: () => import('./views/RateLookupResult.vue')
        },
        {
            path: '/lookup',
            name: 'lookup',
            component: () => import('./views/RateLookup.vue')
        },
        {
            path: '/lookups',
            name: 'lookups',
            component: () => import('./views/LookupHistory.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/Auth/Login.vue')
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import('./views/Auth/ForgotPassword.vue')
        },
        {
            path: '/change-password/:resetToken',
            name: 'change-password',
            component: () => import('./views/Auth/ChangePassword.vue')
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('./views/Settings/Profile.vue')
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('./views/Settings/Settings.vue')
        },
        {
            path: '/billing',
            name: 'billing',
            component: () => import('./views/Settings/Billing.vue')
        },
        {
            path: '/update-plan',
            name: 'update-plan',
            component: () => import('./views/Settings/UpdateBilling.vue')
        },
        {
            path: '/update-payment-info',
            name: 'update-payment-info',
            component: () => import('./views/Settings/UpdateBilling.vue')
        },
        {
            path: '/users',
            name: 'users',
            component: () => import('./views/Settings/Users.vue')
        },
        {
            path: '/user/:userId',
            name: 'user',
            component: () => import('./views/Settings/User.vue')
        },
        {
            path: '/try-free',
            name: 'try-free',
            component: () => import('./views/Signup/Signup.vue')
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import('./views/Signup/Signup.vue')
        },
        {
            path: '/phone-verification',
            name: 'phone-verification',
            component: () => import('./views/Signup/PhoneValidator.vue')
        },
        {
            path: '/plan-selection',
            name: 'plan-selection',
            component: () => import('./views/Signup/FinishSignup.vue')
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: () => import('./views/Terms/PrivacyPolicy.vue')
        },
        {
            path: '/terms-of-use',
            name: 'terms-of-use',
            component: () => import('./views/Terms/TermsOfUse.vue')
        },
        {
            path: '/pricing',
            name: 'pricing',
            component: () => import('./views/Pricing.vue')
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('./views/ContactUs.vue')
        },
        {
            path: '/report-problem',
            name: 'report-problem',
            component: () => import('./views/ReportProblem.vue')
        },
        {
            path: '/api-docs',
            name: 'api-docs',
            component: () => import('./views/ApiDocs/Docs.vue')
        },
        {
            path: '/new-dr0024-sales-tax-receipt',
            name: 'new-dr0024-sales-tax-receipt',
            component: () => import('./views/Help/NewDR0024SalesTaxReceipt.vue')
        },
        {
            path: '/how-colorado-auto-taxes-work',
            name: 'how-colorado-auto-taxes-work',
            component: () => import('./views/Help/HowColoradoAutoTaxesWork.vue')
        },
        {
            path: '/404',
            name: '404',
            component: () => import('./views/404.vue')
        },
        {
            path: '/*',
            redirect: '/404',
        },
    ]
})
