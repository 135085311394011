import Vue from 'vue'

const state = {

}
const getters = {
    auth (state, getters) {
        return state;
    }
};
const actions = {
    setAuth ({ state, commit }, auth) {
        commit('setAuth', auth);
    }
};

const mutations = {
    setAuth (state, auth) {
        if (state.auth !== auth) {
            if (auth === null) {
                for (const prop of Object.keys(state)) {
                    Vue.set(state, prop, null);
                }
                Vue.set(state, 'authenticated', false);
            } else {
                for (const prop of Object.keys(auth)) {
                    Vue.set(state, prop, auth[prop]);
                }
                Vue.set(state, 'authenticated', true);
            }
        }
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
