import Vue from 'vue'
import Vuex from 'vuex'
import lookupParams from './modules/lookupParams.js'
import meta from './modules/meta.js'
import usersParams from './modules/usersParams.js'
import auth from './modules/auth.js'
import stripe from './modules/stripe.js'
import plans from './modules/plans.js'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        lookupParams,
        usersParams,
        auth,
        stripe,
        plans,
        meta,
    },
    strict: debug,
});
