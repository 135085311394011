const state = {
    stripe: null,
}
const getters = {
    stripe (state, getters) {
        return state.stripe;
    }
};
const actions = {
    setStripe ({ state, commit }, stripe) {
        commit('setStripe', stripe);
    }
};

const mutations = {
    setStripe (state, stripe) {
        state.stripe = stripe;
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
