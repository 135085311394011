import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from 'jquery'
import 'popper.js'
import 'bootstrap'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from './store';
import ResponseContainer from '@/components/ResponseContainer.vue';
import './fontAwesome';
import * as Sentry from '@sentry/browser'
import Meta from 'vue-meta'

const VueCookies = require('vue-cookies');
const VTooltip = require('v-tooltip');
const BootstrapVue = require('bootstrap-vue');

Sentry.init({
    dsn: 'https://f0341a4682ae413892b1195411b89b64@sentry.io/1339246',
    integrations: [new Sentry.Integrations.Vue({ Vue })]
}); // */
Vue.component(FontAwesomeIcon.name, FontAwesomeIcon);

Vue.use(VueCookies);
Vue.use(VTooltip);
Vue.use(BootstrapVue);
Vue.use(Meta);

Vue.config.productionTip = false;

$('body').on('click', '[google-track]', function () {
    window.gtag('event', 'click', {
        event_category: 'engagement',
        event_label: $(this).attr('google-track')
    });
});

Vue.directive('button-loading', {
    bind (el, binding, vnode) {
        if (binding.value) {
            $(el).addClass('button-loading').prop('disabled', false);
        }
        $(el).addClass('button-loader');
    },
    update (el, binding, vnode) {
        if (binding.value !== binding.oldValue) {
            if (binding.value) {
                $(el).addClass('button-loading').prop('disabled', true);
            } else {
                $(el).removeClass('button-loading').prop('disabled', false);
            }
        }
    }
});

Vue.directive('show-tooltip-on-text-overflow', {
    bind (el, binding, vnode) {
        setTimeout(() => {
            if (el.offsetWidth >= el.scrollWidth) {
                vnode.data.directives.forEach((d) => {
                    if (d.name === 'tooltip') {
                        d.def.unbind(el);
                    }
                });
            }
        }, 500);
    }
});

Vue.directive('autofocus-on-load', {
    bind (el, binding, vnode) {
        setTimeout(() => {
            $(el).focus()
        }, 100);
    }
});
Vue.component('ResponseContainer', ResponseContainer);
const formatPrice = (amount, decimalCount = 2, decimal = '.', thousands = ',', currencySymbol = '$') => {
    // if (!amount) return '';
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    const j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + currencySymbol + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
};

Vue.filter('formatPrice', function (amount, decimalCount, currencySymbol) {
    return formatPrice(amount, decimalCount, undefined, undefined, currencySymbol);
});

window.dataLayer = window.dataLayer || [];
window.gtag = function () { window.dataLayer.push(arguments); }
window.gtag('js', new Date());

window.gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_ID);

new Vue({
    router,
    render: h => h(App),
    store,
    created () {
        this.installIntercom();
        this.installStripe();

        this.updateAuth(true);
        $(document).ready(() => {
            $(document).on('focus', 'input:text', function () { setTimeout(() => $(this).select(), 0); });
        });
        this.axios.interceptors.request.use((config) => {
            config.withCredentials = true;
            if (this.$store.state.auth.authenticated) {
                config.headers.Authorization = `Bearer ${this.$store.state.auth.origToken}`;
            }
            config.requestStart = (new Date()).getTime();
            return config;
        }, (error) => {
            if (!error.response) {
                error.response = {
                    data: error.message,
                };
            }
            return Promise.reject(error);
        });
        this.axios.interceptors.response.use((response) => {
            if (response.headers['x-dealr-auth'] === 'true') {
                setTimeout(this.updateAuth, 100);
            }
            if (response.headers['x-set-cookie']) {
                try {
                    const cookie = JSON.parse(response.headers['x-set-cookie']);
                    this.$cookies.set(cookie.name, cookie.value, cookie.expires || cookie.maxAge);
                } catch (e) {
                    /* eslint-disable-next-line no-console */
                    console.error(e);
                }
                setTimeout(this.updateAuth, 100);
            }
            const time = (new Date()).getTime();
            if (time - response.config.requestStart < 250) {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve(response);
                    }, 250);
                }); // */
            }
            return response;
        }, (error) => {
            if (error.response) {
                if (error.response.headers['x-dealr-auth'] === 'true') {
                    setTimeout(this.updateAuth, 200);
                }
                switch (error.response.status) {
                    case 401:
                        this.$cookies.remove('DealrAuth', '/', 'localhost');
                        this.$cookies.remove('DealrAuth', '/', '.dealr.tax');
                        this.$cookies.remove('DealrAuth', '/', 'dealr.tax');
                        this.$store.dispatch('auth/setAuth', null);
                        setTimeout(() => {
                            if (this.$route.name !== 'login') {
                                this.$router.push({ name: 'login' });
                            }
                        }, 100);
                        break;
                    case 420:
                        this.$router.push({ name: 'phone-verification' });
                        break;
                    case 421:
                        this.$router.push({ name: 'plan-selection' });
                        break;
                    case 422:
                        this.$router.push({ name: 'home' });
                        break;
                }
            } else {
                error.response = {
                    data: error.message
                };
            }

            return Promise.reject(error);
        });
    },
    data () {
        return {
            axios: axios.create({
                baseURL: process.env.VUE_APP_API_URL,
                timeout: 15000,
            }),
            noNavbarPadding: false,
        };
    },
    watch: {
        async $route (to) {
            window.Intercom('update');
            window.gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_ID, { page_path: to.fullPath });
            if (to.name !== 'pricing' && to.name !== 'home' && this.$store.state.auth.setup) {
                try {
                    await this.$root.axios.get('auth/usernameExists', { params: { UserName: 'redirect' } });
                } catch (e) {

                }
            }
        },
    },
    methods: {
        parseJwt (token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        },
        requireAuth () {
            if (!this.$store.state.auth.authenticated) {
                this.$router.push({ name: 'login', query: { redirect: this.$router.currentRoute.path } });
                return true;
            }
        },
        async updateAuth (initial) {
            const processNoAuth = () => {
                if (initial) {
                    window.Intercom('boot', {
                        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
                    });
                }
                if (this.$store.state.auth.authenticated) {
                    this.$router.push({ name: 'home' });
                    window.Intercom('shutdown');
                }
                this.$store.dispatch('auth/setAuth', null);
            }
            const processAuth = (auth) => {
                const origAuth = auth;
                auth = this.parseJwt(auth);
                auth.origToken = origAuth;
                if (auth.exp < ((new Date()).getTime() / 1000) + 24 * 60 * 60) {
                    this.$cookies.remove('DealrAuth', '/', 'localhost');
                    this.$cookies.remove('DealrAuth', '/', '.dealr.tax');
                    this.$cookies.remove('DealrAuth', '/', 'dealr.tax');
                    this.$store.dispatch('auth/setAuth', null);
                    window.Intercom('shutdown');
                } else {
                    if (initial || !this.$store.state.auth.authenticated) {
                        window.gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_ID, { user_id: auth.user.Id });
                        window.Intercom('boot', {
                            app_id: process.env.VUE_APP_INTERCOM_APP_ID,
                            email: auth.user.Email,
                            user_id: auth.user.Id,
                            user_hash: auth.intercomHash,
                            created_at: auth.user.CreatedAt,
                            name: auth.user.Name,
                            company: {
                                id: auth.company.Id,
                            },
                            master_user: auth.user.IsMaster,
                            trialing: auth.trialing,
                        });

                        window.FS.identify(auth.user.Id, {
                            displayName: auth.user.Name,
                            email: auth.user.Email,
                            pricingPlan_str: auth.plan,
                            companyId_int: auth.company.Id,
                            companyName_str: auth.company.Name,
                        });
                    }

                    const userSplit = auth.user.Name.split(' ');
                    auth.user.Initials = (userSplit[0].slice(0, 1) + (userSplit.length >= 2 ? userSplit[userSplit.length - 1].slice(0, 1) : '')).toUpperCase();
                    this.$store.dispatch('auth/setAuth', auth);
                    if (auth.setup) {
                        setTimeout(async () => {
                            try {
                                await this.$root.axios.get('auth/usernameExists', { params: { UserName: 'redirect' } });
                            } catch (e) {

                            }
                        })
                    }
                }
            }
            const isIE = /* @cc_on!@ */false || !!document.documentMode;
            if (this.$cookies.get('DealrAuth') && !isIE) {
                const auth = this.$cookies.get('DealrAuth');
                processAuth(auth);
            } else {
                try {
                    const result = await axios.get(process.env.VUE_APP_API_URL + 'auth/getAuthCookie', { withCredentials: true });
                    if (result.data.value) {
                        const auth = result.data.value;
                        processAuth(auth);
                    }
                } catch (e) {
                    /* eslint-disable-next-line no-console */
                    console.error(e);
                    processNoAuth();
                }
            }
        },
        async logout () {
            try {
                await this.$root.axios.post('auth/logout');
                this.$cookies.remove('DealrAuth', '/', 'localhost');
                this.$cookies.remove('DealrAuth', '/', '.dealr.tax');
                this.$cookies.remove('DealrAuth', '/', 'dealr.tax');
                this.$store.dispatch('auth/setAuth', null);
                this.$router.push({ name: 'home' });

                setTimeout(() => {
                    location.reload();
                }, 100);
            } catch (e) {
                this.$cookies.remove('DealrAuth', '/', 'localhost');
                this.$cookies.remove('DealrAuth', '/', '.dealr.tax');
                this.$cookies.remove('DealrAuth', '/', 'dealr.tax');
                this.$store.dispatch('auth/setAuth', null);
                alert(e.response.data);
            }
        },
        formatPrice,
        installIntercom () {
            const ic = window.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', {
                    app_id: process.env.VUE_APP_INTERCOM_APP_ID,
                });
            } else {
                const i = function () {
                    i.c(arguments)
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args)
                };
                window.Intercom = i;

                const l = () => {
                    const s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = `https://widget.intercom.io/widget/${process.env.VUE_APP_INTERCOM_APP_ID}`;
                    document.body.appendChild(s);
                }
                if (window.attachEvent) {
                    window.attachEvent('onload', l);
                } else {
                    window.addEventListener('load', l, false);
                }
            }
        },
        installStripe () {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://js.stripe.com/v3/';
            script.onload = () => {
                window.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
                this.$store.dispatch('stripe/setStripe', true);
            };
            document.body.appendChild(script);
        },
    }
}).$mount('#app');

if (!String.prototype.startsWith) {
    // eslint-disable-next-line no-extend-native
    String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}
