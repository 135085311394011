import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
    faChevronDown as farChevronDown,
    faChevronRight as farChevronRight,
    faArrowAltRight as farArrowAltRight,
    faBuilding as farBuilding,
    faCreditCard as farCreditCard,
    faSearch as farSearch,
    faArrowAltUp as farArrowAltUp,
    faPrint as farPrint,
    faPaperPlane as farPaperPlane,
    faPhone as farPhone,
    faUniversalAccess as farUniversalAccess,
    faCertificate as farCertificate,
    faMagic as farMagic,
    faCar as farCar,
    faCode as farCode,
    faTimes as farTimes,
    faCheck as farCheck,
    faPlusCircle as farPlusCircle,
    faInfinity as farInfinity,
} from '@fortawesome/pro-regular-svg-icons'
import {
    faChevronDown as fasChevronDown,
    faArrowAltRight as fasArrowAltRight,
    faBuilding as fasBuilding,
    faCreditCard as fasCreditCard,
    faSearch as fasSearch,
    faArrowAltUp as fasArrowAltUp,
    faPrint as fasPrint,
    faPaperPlane as fasPaperPlane,
    faPhone as fasPhone,
    faUniversalAccess as fasUniversalAccess,
    faCertificate as fasCertificate,
    faMagic as fasMagic,
    faCar as fasCar,
    faCode as fasCode,
    faTimes as fasTimes,
    faCheck as fasCheck,
    faPlusCircle as fasPlusCircle,
    faInfinity as fasInfinity,
} from '@fortawesome/pro-solid-svg-icons'
import {
    faChevronDown as falChevronDown,
    faArrowAltRight as falArrowAltRight,
    faBuilding as falBuilding,
    faCreditCard as falCreditCard,
    faSearch as falSearch,
    faArrowAltUp as falArrowAltUp,
    faPrint as falPrint,
    faPaperPlane as falPaperPlane,
    faPhone as falPhone,
    faUniversalAccess as falUniversalAccess,
    faCertificate as falCertificate,
    faMagic as falMagic,
    faCar as falCar,
    faCode as falCode,
    faTimes as falTimes,
    faCheck as falCheck,
    faPlusCircle as falPlusCircle,
    faInfinity as falInfinity,
} from '@fortawesome/pro-light-svg-icons'

library.add(
    farChevronRight,
    farChevronDown,
    farArrowAltRight,
    farBuilding,
    farCreditCard,
    farSearch,
    farArrowAltUp,
    farPrint,
    farPaperPlane,
    farPhone,
    farUniversalAccess,
    farCertificate,
    farMagic,
    farCar,
    farCode,
    farTimes,
    farCheck,
    farPlusCircle,
    farInfinity,

    fasChevronDown,
    fasArrowAltRight,
    fasBuilding,
    fasCreditCard,
    fasSearch,
    fasArrowAltUp,
    fasPrint,
    fasPaperPlane,
    fasPhone,
    fasUniversalAccess,
    fasCertificate,
    fasMagic,
    fasCar,
    fasCode,
    fasTimes,
    fasCheck,
    fasPlusCircle,
    fasInfinity,

    falChevronDown,
    falArrowAltRight,
    falBuilding,
    falCreditCard,
    falSearch,
    falArrowAltUp,
    falPrint,
    falPaperPlane,
    falPhone,
    falUniversalAccess,
    falCertificate,
    falMagic,
    falCar,
    falCode,
    falTimes,
    falCheck,
    falPlusCircle,
    falInfinity,
);

dom.watch();
