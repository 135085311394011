const state = {
    plans: [
        {
            name: '',
            lookupsIncluded: 0,
            price: 0
        },
        {
            name: 'Free',
            stripeName: 'Free',
            lookupsIncluded: 15,
            price: 0,
        },
        {
            name: 'Starter',
            stripeName: 'Starter',
            lookupsIncluded: 100,
            price: 10,
            autoUse: true,
            buildingUse: true,
            autocomplete: true,
            history: true,
        },
        {
            name: 'Starter',
            stripeName: 'DealerStarter',
            lookupsIncluded: 100,
            price: 25,
            autoUse: true,
            buildingUse: true,
            autocomplete: true,
            history: true,
            salesTaxReceipt: true,
            dealer: true,
        },
        {
            name: 'Business',
            stripeName: 'Business',
            lookupsIncluded: 500,
            price: 40,
            autoUse: true,
            buildingUse: true,
            autocomplete: true,
            history: true,
            overageAllowed: 2000,
            overagePrice: 0.08,
            multiUser: true,
            api: true,
        },
        {
            name: 'Business',
            stripeName: 'DealerBusiness',
            lookupsIncluded: 500,
            price: 80,
            autoUse: true,
            buildingUse: true,
            autocomplete: true,
            history: true,
            salesTaxReceipt: true,
            overageAllowed: 2000,
            overagePrice: 0.08,
            multiUser: true,
            api: true,
            dealer: true,
        },
        {
            name: 'Enterprise',
            stripeName: 'Enterprise',
            lookupsIncluded: 4000,
            price: 200,
            autoUse: true,
            buildingUse: true,
            autocomplete: true,
            history: true,
            overageAllowed: 16000,
            overagePrice: 0.04,
            multiUser: true,
            api: true,
        },
    ],
}
const getters = {
    plans (state, getters) {
        return state.plans;
    }
};
const actions = {

};

const mutations = {

}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
